import React, { ReactElement } from "react";

export default function CardProcessor(): ReactElement {
    return (
        <section className="mt-10 lg:mt-16">
            <h2 className="text-center text-xl font-bold  lg:text-3xl mb-8 lg:mb-10">
                What Is a Credit Card Processor?
            </h2>
            <div className="text-center font-light text-sm lg:text-base">
                A credit card processor facilitates transactions between
                merchants and customers by transmitting data and verifying card
                validity. They act as a middleman between the card network,
                issuing bank, acquiring bank, and parties involved in the
                payment process. The processor charges a fee, usually a
                percentage of the transaction, and ensures the transaction's
                security.
            </div>
        </section>
    );
}
